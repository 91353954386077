import React, { useContext } from "react";
import Button from "../../../components/ui/Button";
import ExamContext from "../../../context/ExamContext";

const ExamOpenButton = ({ previousResults, handleGoToQuestion }) => {
    const { exam } = useContext(ExamContext);
    return (
        <>
            {previousResults.length > 0 &&
            exam.exam_open_limit !== 0 &&
            previousResults.length >= exam.exam_open_limit ? (
                ""
            ) : (
                <>
                    <div className="flex-center-both flex-col space-y-5">
                        {/* {exam.sectionable_id === 101 ? (
                            <div>
                                تنبيه هام: خلّ بالك يا صديقي: الامتحان هيتفتح مرة واحدة بس، ولك
                                محاولة مرة واحدة بس … آخد بالك: مرة وااااااحدة بسسسسسسسس
                            </div>
                        ) : (
                            ""
                        )} */}
                        <Button onClick={handleGoToQuestion} className="px-10 py-2 font-w-bold">
                            فتح الإختبار الآن !
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

export default ExamOpenButton;
