import React from "react";
import CenterIcon from "../components/ui/CenterIcon";
import { Link } from "react-router-dom";
import { description as formatDescription, printRelativeDate, printUnit } from "../utils/ar";

import bgPatternCommunity from "../assets/adel-assets/bgPatternCommunity.svg";
const CommunityCategoryCard = ({ category, type = "card" }) => {
    if (type === "wide") {
        return (
            <Link to={`./category/${category.id}`}>
                <div className="flex relative z-10 justify-between bg-third-container clr-text-primary rounded-md smooth p-5 hover-shadow border-2 border-third-container hover:border-cyan-500">
                    <div className="flex-center-both space-x-2 space-x-reverse">
                        <div className="flex-center-both space-y-2">
                            <div className="md:w-20 w-10 md:h-20 h-10 rounded-full bg-secondary-container flex-center-both smooth">
                                <CenterIcon
                                    className="w-full h-full clr-text-secondary smooth "
                                    iconProperties={{ className: "h-3/4 w-3/4" }}
                                    icon="ic:twotone-group"
                                />
                            </div>
                        </div>
                        <div className="space-y-1">
                            <div className="font-w-bold font-h3">{category.name}</div>
                            <div className="clr-text-secondary smooth">
                                <div className="font-w-bold">{category.description}</div>

                                <div className="font-smaller flex space-x-1 space-x-reverse">
                                    <CenterIcon icon="icon-park-twotone:time" className="pt-0.5" />
                                    <span>
                                        آخر نشاط داخل المجموعة{" "}
                                        {printRelativeDate(category.updated_at)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-center-both">
                        <div className="p-1 bg-secondary-container clr-text-secondary smooth rounded-md shadow-md flex-center-both flex-col space-y-2 text-center font-smaller">
                            <div className="">عدد المواضيع</div>
                            <div>{printUnit(category.community_posts_count, "موضوع")}</div>
                        </div>
                    </div>
                </div>
            </Link>
        );
    } else if (type === "card") {
        return (
            <Link to={`./category/${category.id}`}>
                <div className="flex relative z-10 rounded-md flex-col justify-center space-y-10 text-center py-10 w-[310px] min-h-[350px] sm:w-[350px] border-[1px] border-slate-900 border-solid max-h-[400px] overflow-hidden bg-stone-100 dark:bg-stone-700/50 shadow-md  smooth hover-shadow ">
                    <img
                        src={bgPatternCommunity}
                        alt="bgPatternCommunity"
                        className="absolute inset-0 opacity-80 -z-10"
                    />
                    <div className="flex-center-both flex-col space-y-10">
                        <div className="space-y-1 ">
                            <div className="font-w-bold font-h1 clr-text-primary text-center smooth">
                                {category.name}
                            </div>
                            <div className="clr-text-secondary smooth">
                                <div className="font-w-bold">
                                    {formatDescription(category.description)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-center-both flex-col pt-5 space-y-4">
                        {/* <div className="p-1 bg-secondary-container w-[80%] clr-text-secondary smooth rounded-md shadow-md flex-center-both flex-col space-y-2 text-center font-smaller">
                            <div className="">عدد المواضيع</div>
                            <div>{printUnit(category.community_posts_count, "موضوع")}</div>
                        </div> */}
                        <div className="font-smaller flex space-x-1 space-x-reverse clr-text-secondary smooth">
                            <CenterIcon icon="icon-park-twotone:time" className="pt-0.5" />
                            <span>
                                آخر نشاط داخل المجموعة {printRelativeDate(category.updated_at)}
                            </span>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
};

// ic:twotone-group
export default CommunityCategoryCard;
