import React from "react";
import { Outlet } from "react-router-dom";
import HeaderSection from "../components/ui/HeaderSection";
import Container from "../components/ui/Container";

import communityHero from "../assets/adel-assets/communityHero.png";
import BgInkPattern from "../assets/adel-assets/inkpattern.png";
import oldPaperIcon from "../assets/adel-assets/OldPaperIcon.png";

const CommunityHome = () => {
    return (
        <>
            <div className=" smooth clr-text-primary  posisitve-nav-padding-top">
                <Container className="py-10 pb-10  space-y-0">
                    <div className="w-full rounded-2xl py-10 md:py-2 px-10 md:px-0 smooth relative overflow-hidden z-10 flex md:flex-row-reverse flex-col h-full space-y-10 md:space-y-0 ">
                        <img
                            src={BgInkPattern}
                            alt="BgInkPattern"
                            className="absolute inset-0 -z-10 opacity-20"
                        />
                        <div className="basis-full md:basis-1/2 z-10 flex-center-both">
                            <div className="flex md:items-start  justify-center flex-col space-y-10 md:space-y-0 font-graphic">
                                <h1 className="leading-[.9] relative text-center lg:text-right">
                                    <span className="font-dg text-stone-500 dark:text-[#f7f7f7] smooth text-[3.5rem] lg:text-[4.5rem]">
                                        أسألنا براحتك؟ <br />
                                        <div className="flex gap-4 items-center">
                                            <img
                                                src={oldPaperIcon}
                                                alt="oldPaperIcon"
                                                className="h-8 hidden md:block"
                                            />
                                            <span className=" text-xl md:text-2xl text-yellow-500">
                                                #كلام ـ مؤرخين .. #ثانوية ـ عامة{" "}
                                            </span>
                                        </div>
                                    </span>
                                </h1>
                                <p className="text-center md:text-right text-3xl clr-text-primary smooth font-lama w-full pt-5 md:pt-10 lg:w-[60%]">
                                    هنا هتلاقي كل إجابة لأسئلتك، تاريخنا هو البداية وراحتك هي هدفنا!
                                </p>
                            </div>
                        </div>
                        <div className="basis-full md:basis-1/2 z-10 h-full flex-center-both pt-10 md:pt-0">
                            <div className="flex-center-both max-w-3xl px-10 sm:px-10">
                                <img src={communityHero} alt="communityHero" className="w-full" />
                            </div>
                        </div>
                    </div>
                </Container>
                <div className="w-full  relative  clr-text-primary smooth">
                    <Outlet />
                </div>
                <Container></Container>
            </div>
        </>
    );
};

export default CommunityHome;
